<template>
  <c-box w="100%">
    <c-flex
      marginBottom="30px"
      w="100%"
      px="10px"
      align-items="center"
      gridGap="10px"
    >
      <c-text fontSize="24px" fontWeight="700" color="brand.900">
        Data Menu
      </c-text>
      <c-button
        v-if="isRemovable"
        variant="ghost"
        m="0"
        p="5px"
        min-w="auto"
        w="auto"
        h="auto"
        border-radius="25px"
        border="1px solid #343434"
        @click.stop="onRemove()"
        type="button"
      >
        <c-icon name="close" size="14px" />
      </c-button>
    </c-flex>
    <c-flex>
      <c-form-control is-required :is-invalid="$v.dose.$invalid" marginBottom="30px" w="50%" px="10px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Jumlah Takaran
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="number"
            placeholder="Masukkan Jumlah Takaran"
            height="62px"
            v-model.number="dose"
          />
        </c-input-group>
        <c-form-helper-text v-if="!$v.dose.required" color="red.500" font-size="12px">
          Jumlah Takaran tidak boleh kosong
        </c-form-helper-text>
      </c-form-control>
      <c-form-control is-required :is-invalid="$v.doseUnit.$invalid" marginBottom="30px" w="50%" px="10px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Satuan Takaran
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            placeholder="Masukkan Satuan Takaran"
            height="62px"
            v-model="doseUnit"
          />
        </c-input-group>
        <c-form-helper-text v-if="!$v.doseUnit.required" color="red.500" font-size="12px">
          Satuan Takaran tidak boleh kosong
        </c-form-helper-text>
      </c-form-control>
    </c-flex>
    <c-form-control is-required :is-invalid="$v.foodIngredient.$invalid" marginBottom="30px" w="100%" px="10px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Nama Makanan
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          placeholder="Masukkan Nama Makanan"
          height="62px"
          v-model="foodIngredient"
        />
      </c-input-group>
      <c-form-helper-text v-if="!$v.foodIngredient.required" color="red.500" font-size="12px">
        Nama Makanan tidak boleh kosong
      </c-form-helper-text>
    </c-form-control>
    <c-form-control is-required
                    :is-invalid="$v.mealGlossaryId.$invalid"
                    :class="{invalid: $v.mealGlossaryId.$invalid}"
                    marginBottom="30px" w="100%" px="10px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Glossary Makanan
      </c-form-label>
      <autocomplete
        placeholder="Pilih glossary makanan"
        :search="onSearch"
        :getResultValue="(it) => it.ingredientName"
        :defaultValue="ingredientName_"
        v-chakra
        w="100%"
        auto-select
        @submit="onSubmit($event)"
      >
        <template
          #default="{
            rootProps,
            inputProps,
            inputListeners,
            resultListProps,
            resultListListeners,
            results,
            resultProps,
          }"
        >
          <c-flex v-bind="rootProps">
            <input
              v-bind="inputProps"
              v-on="inputListeners"
              height="62px"
              v-chakra="{
                bg: 'white !important',
                px: '20px !important',
                border: '1px solid red.500 !important',
              }"
            />
            <ul
              v-bind="resultListProps"
              v-on="resultListListeners"
              v-chakra="{
                zIndex: '1000 !important',
              }"
            >
              <li
                v-for="(result, index) in results"
                :key="resultProps[index].id"
                v-bind="resultProps[index]"
                d="flex"
                align-items="center"
                gridGap="10px"
                v-chakra="{
                  bg: 'white !important',
                  p: 0,
                }"
                :_hover="{ bg: '#fafafa !important' }"
              >
                <c-image h="64px" w="64px" object-fit="cover" :src="result.photoUrl" />
                <c-text flex="1">{{ result.ingredientName }}</c-text>
              </li>
            </ul>
          </c-flex>
        </template>
      </autocomplete>
      <c-form-helper-text v-if="!$v.mealGlossaryId.required" color="red.500" font-size="12px">
        Glossary Makanan tidak boleh kosong
      </c-form-helper-text>
    </c-form-control>
  </c-box>
</template>

<script>
import _ from "lodash";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";
import equal from "fast-deep-equal";
import {required} from "vuelidate/lib/validators";

export default {
  name: "form-data-menu",
  props: ["value", "isRemovable", "glossaries"],
  components: {
    Autocomplete,
  },
  data() {
    return {
      dose: null,
      doseUnit: null,
      foodIngredient: null,
      mealGlossaryId: null,
      mealIngredientNameGlossary: null,
    };
  },
  computed: {
    ingredientName_() {
      return this.value?.mealIngredientNameGlossary?.ingredientName;
    },
    foodIngredientText() {
      return this.value.mealIngredientNameGlossary?.foodIngredient;
    },
    values_() {
      return {
        id: this.value.id,
        dose: this.dose,
        doseUnit: this.doseUnit,
        foodIngredient: this.foodIngredient,
        mealGlossaryId: this.mealGlossaryId,
        mealIngredientNameGlossary: this.mealIngredientNameGlossary,
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val, old) {
        if (val == null) return;
        if (equal(val, old)) return;

        this.setIfNull('dose', val.dose)
        this.setIfNull('doseUnit', val.doseUnit)
        this.setIfNull('foodIngredient', val.foodIngredient)
        this.setIfNull('mealIngredientNameGlossary', val.mealIngredientNameGlossary)

        if (this.mealGlossaryId == null && this.mealIngredientNameGlossary != null) {
          this.mealGlossaryId = this.mealIngredientNameGlossary.id
        }
      },
    },
    values_: _.debounce(function(val, old) {
      if (equal(val, old)) return;

      this.$emit("input", val);
    }, 500),
  },
  async mounted() {
    await this.$store.dispatch("glossary/list");
  },
  methods: {
    setIfNull(key, val) {
      if (this[key] == null) {
        this[key] = val;
      }
    },
    logDiff(tag, diffKeys, _new, _old) {
      // console.log('diffKeys', diffKeys)
      for (let key of diffKeys) {
        // console.log(`[${tag}] _new.${key}`, `'${_new?.[key]}'`)
        // console.log(`[${tag}] _old.${key}`, `'${_old?.[key]}'`)
        console.log(`[${tag}] from.${key}(${_old?.[key]}) to.${key}(${_new?.[key]})`)
      }
    },
    getObjectDiff(obj1 = {}, obj2 = {}) {
      const diff = Object.keys(obj1).reduce((result, key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!obj2.hasOwnProperty(key)) {
          result.push(key);
        } else if (_.isEqual(obj1[key], obj2[key])) {
          const resultKeyIndex = result.indexOf(key);
          result.splice(resultKeyIndex, 1);
        }
        return result;
      }, Object.keys(obj2));

      return diff;
    },
    onSubmit($event) {
      console.log('$event', $event)
      this.mealGlossaryId = $event.id
    },
    async onSearch(search) {
      if (search == null || search.length <= 0) {
        return this.glossaries;
      }
      return this.$store.dispatch("glossary/list", { q: search }).then(it => it.result);

      // return this.glossaries.filter(
      //   (it) =>
      //     it.foodIngredient.toLowerCase().indexOf(search.toLowerCase()) > -1
      // );
    },
    onRemove() {
      this.$emit("input", {
        ...this.values_,
        status: "deleted",
      });
    },
  },
  validations: {
    dose: { required },
    doseUnit: { required },
    foodIngredient: { required },
    mealGlossaryId: { required },
  },
};
</script>

<style>
.invalid .autocomplete-input {
  border-color: #e66673;
  box-shadow: 0 0 0 1px #e66673;
}
</style>
