<template>
  <c-box px="20px">
    <c-box marginBottom="30px">
      <c-text
        fontSize="16px"
        fontWeight="700"
        fontFamily="Roboto"
        color="brand.900"
      >
        Rekomendasi Menu
      </c-text>
      <c-text fontSize="24px" fontWeight="700" color="black.900">
        {{ title }}
      </c-text>
    </c-box>
    <c-flex
      flexWrap="wrap"
      mx="-10px"
      v-chakra="{
        '.disabled': {
          borderColor: '#C4C4C4',
          backgroundColor: 'superLightGray.900',
          color: 'black.900',
          cursor: 'not-allowed',
          pointerEvents: 'none',
          button: {
            borderColor: '#C4C4C4',
          },
        },
      }"
    >
      <c-form-control marginBottom="30px" w="50%" px="10px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Jam Mulai Makan
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            id="time1"
            height="62px"
            class="disabled"
            :value="timeStart"
          />
          <c-input-right-element width="4.5rem" mt="10px">
            <c-image :src="require('@/assets/icon-jam-grey.svg')" alt="image" />
          </c-input-right-element>
        </c-input-group>
      </c-form-control>
      <c-form-control marginBottom="30px" w="50%" px="10px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Jam Akhir Makan
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            id="time2"
            :value="timeEnd"
            height="62px"
            class="disabled"
          />
          <c-input-right-element width="4.5rem" mt="10px">
            <c-image :src="require('@/assets/icon-jam-grey.svg')" alt="image" />
          </c-input-right-element>
        </c-input-group>
      </c-form-control>
      <c-form-control marginBottom="30px" w="100%" px="10px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Kebutuhan Jumlah Kalori
        </c-form-label>
        <c-input-group size="md">
          <c-select
            class="disabled"
            is-disabled
            :placeholder="`${caloriesNeeded}%`"
            bg="#f2f2f2"
            height="62px"
          />
        </c-input-group>
      </c-form-control>
      <c-form-control marginBottom="30px" w="100%" px="10px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Kkal - Jumlah Kalori
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            :value="percentageTotalCalories"
            height="62px"
            class="disabled"
          />
        </c-input-group>
      </c-form-control>

      <template v-for="(meal, index) in value">
        <FormDataMenu
          ref="formDataMenu"
          :key="index"
          :is-removable="true"
          :glossaries="glossaries"
          :value="meal"
          @input="$emit('input', $event)"
        />
      </template>
      <c-form-control w="100%" marginBottom="30px" d="flex">
        <c-button
          bg="white"
          fontSize="18px"
          fontWeight="500"
          color="brand.900"
          minHeight="61px"
          borderRadius="100px"
          left-icon="add"
          border="1px solid #008C81"
          w="95%"
          mx="auto"
          textAlign="center"
          @click="$emit('add')"
          :is-disabled="isInvalid"
        >
          Tambah Data Menu Lain
        </c-button>
      </c-form-control>

      <c-flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        marginTop="30px"
      >
        <c-button
          left-icon="chevron-left"
          backgroundColor="brand.900"
          color="white"
          borderRadius="100px"
          variant="solid"
          px="30px"
          @click="$emit('prev')"
        >
          Kembali
        </c-button>
        <c-button
          right-icon="chevron-right"
          backgroundColor="brand.900"
          color="white"
          borderRadius="100px"
          variant="solid"
          px="30px"
          @click="$emit('next')"
          :is-disabled="isInvalid"
        >
          Selanjutnya
        </c-button>
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
import FormDataMenu from "@/views/meal-plan/forms/form-data-menu.vue"

export default {
  name: "form-edit-menu-rekomendasi",
  components: { FormDataMenu },
  props: [
    "glossaries",
    "title",
    "timeStart",
    "timeEnd",
    "caloriesNeeded",
    "totalCalories",
    "selectedDay",
    "value",
  ],
  data() {
    return {
      isInvalid: false,
    }
  },
  updated() {
    this.isInvalid = this.$refs.formDataMenu?.some(
      (it) => it?.$v.$invalid === true
    )
  },
  computed: {
    percentageTotalCalories() {
      return this.totalCalories / 100
    },
  },
}
</script>

<style scoped></style>
