var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-flex',{attrs:{"flex":"1","flex-dir":"column","overflow":"auto","pb":"30px"}},[_c('c-box',{attrs:{"px":"40px","py":"40px"}},[_c('c-flex',{attrs:{"justifyContent":"space-between"}},[_c('c-heading',{attrs:{"color":"black.900","fontSize":"24px","fontWeight":"700","marginBottom":"40px"}},[_vm._v(" Rekomendasi Menu ")]),_c('c-button',{attrs:{"backgroundColor":"brand.900","fontSize":"16px","fontWeight":"700","fontFamily":"Roboto","px":"20px","borderRadius":"100px","color":"white","is-disabled":_vm.isInvalid},on:{"click":function($event){return _vm.simpan()}}},[_c('c-image',{attrs:{"src":require('@/assets/save-icon.svg'),"alt":"image","marginRight":"8px"}}),_c('c-text',[_vm._v("Simpan")])],1)],1),_c('c-flex',{attrs:{"mt":"15px","overflow-x":"auto"}},_vm._l((10),function(_,index){return _c('badge-week',{directives:[{name:"chakra",rawName:"v-chakra"}],key:index,attrs:{"selected":index + 1 === _vm.selectedWeek,"week":index + 1,"dotted":!_vm.daysWithData.includes(index + 1),"ml":index !== 0 ? '10px' : ''},on:{"click":function($event){_vm.selectedWeek = index + 1}}})}),1),_c('c-flex',{directives:[{name:"chakra",rawName:"v-chakra",value:({
        div: {
          width: '100%',
          maxWidth: '156px',
          marginRight: '12px',
          textAlign: 'center',
          ':last-child': {
            marginRight: '0',
          },
          p: {
            color: 'lightGray.900',
            fontWeight: '700',
            marginBottom: '10px',
          },
          '.step': {
            w: '100%',
            h: '8px',
            maxWidth: '156px',
            borderRadius: '20px',
            backgroundColor: 'superLightGray.900',
          },
          '&.done': {
            p: {
              color: 'brand.900',
            },
            '.step': {
              backgroundColor: 'brand.900',
            },
          },
          '&.selected': {
            p: { color: 'gray.700' },
            '.step': { bg: 'gray.700' },
          },
        },
      }),expression:"{\n        div: {\n          width: '100%',\n          maxWidth: '156px',\n          marginRight: '12px',\n          textAlign: 'center',\n          ':last-child': {\n            marginRight: '0',\n          },\n          p: {\n            color: 'lightGray.900',\n            fontWeight: '700',\n            marginBottom: '10px',\n          },\n          '.step': {\n            w: '100%',\n            h: '8px',\n            maxWidth: '156px',\n            borderRadius: '20px',\n            backgroundColor: 'superLightGray.900',\n          },\n          '&.done': {\n            p: {\n              color: 'brand.900',\n            },\n            '.step': {\n              backgroundColor: 'brand.900',\n            },\n          },\n          '&.selected': {\n            p: { color: 'gray.700' },\n            '.step': { bg: 'gray.700' },\n          },\n        },\n      }"}],attrs:{"py":"30px","w":"100%","justifyContent":"space-evenly","alignItems":"flex-end"}},_vm._l((_vm.categories),function(category){return _c('c-box',{key:category,class:_vm.selectedCategory === category ? 'selected' : ''},[_c('c-text',[_vm._v(_vm._s(_vm._f("categoryTitle")(category)))]),_c('c-box',{staticClass:"step"})],1)}),1)],1),_vm._l((_vm.categories),function(category){return [(category === _vm.selectedCategory)?_c('FormEditMenuRekomendasi',{key:category,ref:"formEdit",refInFor:true,attrs:{"title":_vm._f("categoryTitle")(category),"selected-day":_vm.selectedWeek,"category-data":_vm.selectedCategoryData,"time-start":_vm.selectedCategoryData.timeStart,"time-end":_vm.selectedCategoryData.timeEnd,"calories-needed":_vm.selectedCategoryData.caloriesNeed,"total-calories":_vm.selectedCategoryData.totalCalories,"glossaries":_vm.glossaries,"value":_vm.sortedSelectedMeals},on:{"input":function($event){return _vm.onInputMeals({
          category: category,
          programId: _vm.programId,
          mealPlansId: _vm.mealPlansId,
          clientId: _vm.clientId,
          day: _vm.selectedWeek,
          meal: $event,
        })},"next":function($event){return _vm.next()},"prev":function($event){return _vm.prev()},"add":function($event){return _vm.onAddMeal()}}}):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }