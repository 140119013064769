import { render, staticRenderFns } from "./form-edit-menu-rekomendasi.vue?vue&type=template&id=4e358284&scoped=true&"
import script from "./form-edit-menu-rekomendasi.vue?vue&type=script&lang=js&"
export * from "./form-edit-menu-rekomendasi.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e358284",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CFormLabel: require('@chakra-ui/vue').CFormLabel, CInput: require('@chakra-ui/vue').CInput, CImage: require('@chakra-ui/vue').CImage, CInputRightElement: require('@chakra-ui/vue').CInputRightElement, CInputGroup: require('@chakra-ui/vue').CInputGroup, CFormControl: require('@chakra-ui/vue').CFormControl, CSelect: require('@chakra-ui/vue').CSelect, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex})
